@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: 255 153 0;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* html,
body,
#root,
.okvip-app {
  height: 100%;
  overflow: hidden;
} */

input:focus {
  transform: none !important;
}

body::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

body::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}

body::-webkit-scrollbar-corner {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #bbb;
  border-radius: 8px;
}

.bg-form-789{
  border-radius: 9.315px;
  border: 0.847px solid #FC8F00;
  background: linear-gradient(180deg, rgba(0, 5, 31, 0.90) 0%, rgba(42, 67, 115, 0.70) 100%);
  min-height: 450px;
}

.bg-form-jun88cmd{
  border-radius: 12px;
  border: 1px solid #26A8DF;
  background: linear-gradient(180deg, rgba(113, 108, 206, 0.40) 0%, rgba(0, 172, 236, 0.40) 100%), linear-gradient(180deg, rgba(0, 5, 31, 0.90) 0%, rgba(42, 67, 115, 0.70) 100%);
  min-height: 450px;
  --tw-shadow-colored: 0 1px 3px 0 #ffffff, 0 1px 2px -1px #ffffff;
}

.bg-form-jun88cmd header .bg-header-main{
  background: linear-gradient(180deg, #8BC7DF 0%, #00ACEC 100%);
}

.bg-form-jun88k36{
  border-radius: 9.315px;
  border: 0.847px solid #9FBBD6;
  background: rgba(100, 193, 237, 0.85);
  box-shadow: -3px -3px 15px 0px rgba(27, 60, 78, 0.58), 3px 3px 15.8px 0px rgba(27, 60, 78, 0.58);
}

.jun88k36 .bg-box-frame{
  background: url("../assets/images/jun88k36-frame.png");
  background-size: 100% 100%;
  background-position: 50%;
}
.bg-box-frame{
  background: url("../assets/images/mobi-frame.png");
  background-size: 100% 100%;
  background-position: 50%;
  border-radius: 10px;
  box-sizing: border-box;
  z-index: 99;
}
.swiper-slide {
  text-align: center;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.swiperDate {
  --swiper-navigation-size: 20px;
  --swiper-theme-color: #ffffff;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  height: 100% !important;
  margin: 0 !important;
  top: 0 !important;
  width: 20px !important;
  z-index: 11;
}

.swiper-button-prev {
  left: 0;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.swiper-button-next {
  right: 0;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.okvip-input-number,
.okvip-input-number-input-wrap {
  padding: 0;
  text-align: center;
  width: 60px;
  height: 30px;
}
.okvip-input-number-input-wrap .okvip-input-number-input{
  padding: 3px 8px;
}


.list-match{
  max-height: 500px;
  overflow: auto;
}

.list-match::-webkit-scrollbar {
  height: 8px;
  width: 3px;
}

.list-match::-webkit-scrollbar-corner {
  background: #ff9800;
}

.list-match::-webkit-scrollbar-thumb {
  background-color: #ff9800;
  border-radius: 8px;
}

.okvip-input-number-input {
  font-weight: 600;
  text-align: center !important;
}
.okvip-statistic .okvip-statistic-title{
  color: #000;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
.okvip-statistic-content-value {
  font-size: 16px;
  color: #FFF;
  font-weight: 700;
}
.jun88k36 .okvip-statistic-content-value{
  color: #162DFF;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 0 !important;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 0 !important;
}
.bo_789bet .bg-score {
  min-width: 105px;
  border-radius: 4px;
  border: 1px solid #CACACA;
  background: rgba(255, 255, 255, 0.40);
  box-shadow: 0px 2px 4.2px 0px rgba(0, 0, 0, 0.80);
}
.bo_789bet .bg-score:hover {
  background: #FF9800;
}
.bo_789bet .bg-score.bg-check-account{
  background: #FF9800;
}

.jun88cmd .bg-score {
  min-width: 105px;
  border-radius: 4px;
  border: 1px solid #CACACA;
  background: rgba(255, 255, 255, 0.40);
  box-shadow: 0px 2px 4.2px 0px rgba(0, 0, 0, 0.80);
}
.no-check-account {
  transition: all 1s;
  transition-timing-function: cubic-bezier(0.5, 1, 1, 1);
}
.no-check-account:hover {
  transform: scale(1.1, 1.1);
 }
.jun88cmd .okvip-btn-primary:not(:disabled):not(.okvip-btn-disabled):hover {
  background: #108ABD;
}
.jun88cmd .okvip-input-affix-wrapper:hover {
  border-color: #108ABD;
}

.jun88cmd .bg-score:hover {
  background: #1099d4;
}


.jun88cmd .okvip-btn-default:not(:disabled):not(.okvip-btn-disabled):hover{
  background: #108ABD;
  color: #ffffff;
  border-color: #ffffff;
}

.jun88cmd .bg-score.bg-check-account{
  border-radius: 3.451px;
  border: 0.863px solid #CACACA;
  background: #26A8DF;
  box-shadow: 0px 1.726px 3.624px 0px rgba(0, 0, 0, 0.80);
}

.jun88k36 .bg-score {
  min-width: 105px;
  border-radius: 4px;
  border: 1px solid #CACACA;
  background: rgb(69 169 199 / 80%);
  box-shadow: 0px 2px 4.2px 0px rgba(0, 0, 0, 0.80);
}
.jun88k36 .bg-score:hover {
  background: #26A8DF;
}
.jun88k36 .bg-score.bg-check-account{
  background: #26A8DF;
}
.jun88k36 .bg-score.bg-check-account:hover{
  background: #e90b0b;
}

.thumb-logo-team .okvip-avatar{
  width: 68px;
  height: 68px;
  border-radius: 50%;
}


.jun88.thumb-logo-team .okvip-avatar{
  border-radius: initial;
    height: 42px;
    overflow: hidden;
    padding-bottom: 60%;
    position: relative;
    width: 68px;
}

.jun88.thumb-logo-team .okvip-avatar img {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}



.NEW88 .swiper-button-prev,
.NEW88 .swiper-button-next {
  background-image: linear-gradient(to bottom, #ff9800, #fe4451);
}

.NEW88 .swiper-slide-active {
  background-image: linear-gradient(to bottom, #ff9800, #fe4451);
}

.NEW88 .bg-score {
  background-image: linear-gradient(to bottom, #ff9800, #fe4451);
}

.SHBET .swiper-button-prev,
.SHBET .swiper-button-next {
  background-image: linear-gradient(to bottom, #6cb5cc, #2f7899);
}

.SHBET .swiper-slide-active {
  background-image: linear-gradient(to bottom, #6cb5cc, #2f7899);
}

.SHBET .bg-score {
  background-image: linear-gradient(to bottom, #6cb5cc, #2f7899);
}
.okvip-modal{
  top: 50px;
  max-width: 1024px !important;
  width: 100% !important;
}
.modal-jun88k36 table td{
  border: 1px solid #f8f8ff;
}

.okvip-modal-body p img {
  max-width: 100%;
  margin: auto;
  display: flex;
}

.okvip-modal.match-football{
  top: 24px;
  max-width: 1200px !important;
  width: 100% !important;
}
.match-football .okvip-modal-header{
  text-align: center;
}
.match-football .okvip-modal-header .okvip-modal-title{
  font-size: 18px;
  font-weight: 700;
}
li, ul {
  list-style: none;
}
.bg-check-day{
  background: #FF9800;
  box-shadow: 0px 2px 4.2px 0px rgba(0, 0, 0, 0.80);
}
.jun88k36 .bg-check-day{
  background: #26A8DF;
}
.jun88cmd .bg-check-day{
  background: #26A8DF;
}
.text-fixed-header{
  position: fixed;
  top: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.box-text{
  background: #7A7A7A;
  border-radius: 4px;
}
.icon-speaker{
  width: 24px;
  height: 24px;
}
.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
}

.marquee {
  padding-left: 100%;
  animation: marquee 24s linear infinite;
}
.marquee-text {
  display: inline-block;
  font-weight: 600;
  color: #fff;
}
.jun88k36 .marquee-text{
  color: #fff;
}
.image-gif{
  bottom: 0;
}
.page-prize img {
  max-width: calc(100% + 30px);
  object-fit: cover;
  height: auto;
  margin: auto;
  text-align: center;
  margin-left: -15px;
}
.page-prize {
  background: #fff;
  padding: 0 15px 15px;
}
/* .content-rank-matches{
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
  gap: 24px;
} */

.content-rank-matches{
  margin: auto;
  max-width: 800px;
  display: flex;
}



.table-scrol-x {
  overflow-x: auto;
  white-space: nowrap;
  float: left;
  width: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100% !important;
  font-size: 14px;
  border: 1px solid #eaeaea;
}
.table-bxh th {
  font-size: 14px;
  font-weight: normal;
  background: #f0f0f0;
  border: 0;
  border-bottom: solid 1px #eaeaea;
  padding: 12px 6px;
  vertical-align: top;
  position: relative;
  font-weight: 700;
}
.w-stt {
  width: 7%;
  min-width: 40px;
}
.bang-abc {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #e9e9e9;
  width: 100%;
  display: grid;
  place-items: center;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
.w-ten-clb {
  width: 33%;
  text-align: left;
}
.table-bxh td {
  font-size: 14px;
  position: relative;
  border: 0;
  border-bottom: solid 1px #eaeaea;
  padding: 12px 3px;
  vertical-align: top;
  text-align: center;
  color: #FFF;
  font-weight: 600;
}
.link-clb {
  display: block;
  text-align: left;
  padding-left: 30px;
  position: relative;
}
.bxhclb-icon {
  height: 24px;
  width: 24px;
  float: left;
  position: absolute;
  top: -1px;
  border-radius: 100%;
  left: 0;
}
.ranknum {
  display: inline-block;
  color: #fff;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 100%;
}
.rank1 {
  background-color: #05b349;
}
.rank2 {
  background-color: #05b349;
}
.rank3 {
  background-color: #ecb305;
}
.rank4 {
  background-color: #f61010;
}
.table-bxh tr:last-child td:first-child{
  position: relative;
  z-index: 99;
}

.table-bxh tr:last-child td:first-child:before{
  content: '4';
  display: inline-block;
  color: #fff;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 100%;
  background-color: #f61010;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  transform: translate(-50%,-50%);
}
.color-info {
  display: none;
  float: left;
  width: 100%;
  position: relative;
  margin-top: 6px;
  font-size: 14px;
  color: #666;
}
.color-info ul {
  margin: 6px 0 10px 0;
  float: left;
}
.fl-left {
  float: left;
}
.itemcolor {
  display: inline-block;
  position: relative;
  margin-bottom: 6px;
  margin-right: 12px;
}
.bgcolor {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin-right: 5px;
}
.logo-team-matches svg{
  border-radius: 50%;
}
.w-6.h-6.relative.logo-team-matches {
  flex-shrink: 0;
}
.box-matches .okvip-tabs-nav-list .okvip-tabs-tab{
  border: unset;
  color: #FFF;
}
.okvip-tabs-nav-more{
  color: #FFF !important;
}
.custom-panel-finals.grid{
  margin-left: 25%;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  margin-right: 25%;
}
.mobile-scroll {
  overflow-x: auto;
}
.mobile-scroll .item-nav {
  flex-shrink: 0; /* Prevent items from shrinking */
}
.no-data-rank{
  font-weight: 500;
  font-size: 18px;
  color: #FFF;
}
.box-matches-jun88k36 .okvip-tabs-nav-wrap{
  background: #292929cf;
}
.box-matches-jun88k36 .okvip-tabs-nav-wrap .okvip-tabs-tab.okvip-tabs-tab-active{
  border-radius: unset !important;
}
.item-banner {
  max-height: 130px;
  overflow: hidden;
  padding: 0 5px;
}
.item-banner img {
  border-radius: 8px; 
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* css Euro bản 2 */
.block-football {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.block-football:last-child {
  margin-bottom: 0;
}
.block-football .row {
  width: 100%;
  float: left;
  margin-bottom: 16px;
}

.fix-left {
  display: flex;
  gap: 8px;
}

.f-ab {
  display: inline-block;
  text-align: center;
  padding: 6px 12px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  background: #f1f1f1;
}

.text-center {
  text-align: center;
}

.block-football .table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.bg-ligh {
  background: #f0f0f0;
}

.block-football .align-top td,
.block-football .align-top th {
  vertical-align: top;
}

.block-football .table td,
.block-football .table th {
  padding: 6px;
  border-top: solid 1px #e9e9e9;
}

.block-football .table td{
  color: #fff;
}
.block-football .table-scrol-x table tbody tr td:first-child{
  font-weight: 700;
}
.normal {
  font-weight: normal;
}

.color-dark {
  color: #000;
  font-weight: 600;
}

.hide-pc {
  display: none;
}

.td-bang-doi {
  width: 30px;
  border-left: solid 2px #ff0000;
}
.td-bang-doi.v-green {
  border-left: solid 2px #5ab54d;
}
.v-orange {
  border-left: solid 2px #ffa500;
}


.td-ten-doi {
  width: 40%;
}

.link-club {
  text-align: left;
  min-width: 130px;
}

.link-club a {
  display: inline-block;
}

.ic-link-club {
  height: 16px;
  width: 20px;
  float: left;
  margin-right: 8px;
  position: relative;
  top: 2px;
}

.text-link-club {
  display: grid;
}

.icon-tktt {
  float: right;
  width: 70px;
}
.iconbxhtt {
  display: inline-block;
  height: 16px;
  width: 16px;
  margin: 0 1px;
}
.w-bang-doi {
  text-align: left;
}
.uppercase {
  text-transform: uppercase;
}
.f-ab.active {
  color: #fff;
  background: #00008b;
}

.content-km{
  font-family: Times New Roman, Times, serif !important;
}
/* css euro2 */
.okvip-input-number .okvip-input-number-input{
  font-weight: 700;
}
.okvip-input-number-disabled .okvip-input-number-input{
  color: #000;
}
.modal-history .okvip-modal-title{
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .mobile-scroll {
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }
  .mobile-scroll .item-nav {
    min-width: fit-content; /* Ensure each menu item takes enough space */
  }
  .bg-form-789{
    background: linear-gradient(180deg, rgb(0 5 31 / 60%) 0%, rgba(42, 67, 115, 0.70) 100%);
    /* padding: 0 6px; */
  }
  .thumb-logo-team .okvip-avatar{
    width: 46px;
    height: 46px;
  }
  .jun88.thumb-logo-team .okvip-avatar {
    height: 34px;
    width: 54px;
  }
  .okvip-input-number-input-wrap .okvip-input-number-input{
    padding: 1px 4px;
  }
  .thumb-image-vs{
    width: 36px;
  }
  .okvip-input-number,
  .okvip-input-number-input-wrap {
    padding: 0;
    text-align: center;
    width: 46px;
    height: 28px;
  }
  .okvip-input-number-input {
    padding: 2px 8px;
  }
  .text-fixed-header{
    background: #3E3E3E;
    height: 22px
  }
  .marquee-text{
    color: #FFF;
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .okvip-modal{
    top: 32px;
  }
  .image-gif{
    bottom: -60px;
  }
  .image-gif-footer{
    transform: scale(1.1, 1.1);
  }
  .content-rank-matches{
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 16px 0;
  }
  .custom-panel-finals.grid{
    margin-left: 0;
    margin-right:0;
  }
  /* .rank-matches{
    margin-left: -16px;
    margin-right: -16px;
  } */
  .link-clb{
    padding-left: 24px;
  }
  .modal-jun88k36 .okvip-modal-content{
    padding: 15px;
  }
  .page-prize table tr td:first-child{
    display: none;
  }
  .page-prize p span{
    font-size: 14px !important;
    line-height: normal;
  }
  .page-prize p{
    margin: 0 !important;
    /* line-height: normal; */
  }
  .page-prize table tr {
    height: 42.6875px !important;
  }
  .page-prize table tr td {
    height: 42.6875px !important;
  }
  .jun88cmd .page-prize table tr {
    height: auto !important;
  }
  .jun88cmd .page-prize table tr td {
    height: auto !important;
  }
  .page-prize table tr td span{
    font-size: 16px !important;
  }
  .page-prize table{
    margin-left: -15px;
    margin-right: -15px !important; 
    width: calc(100% + 30px) !important;
  }
  .th-data .hi_m {
    display: none;
  }
  .th-data::before {
    content: attr(data-name) "";
    display: block;
  }
  .item-banner{
    padding: 0 4px;
  }
  .btn-header-top .bg-score{
    min-width: auto;
    border: unset;
    background: unset;
    box-shadow: unset;
  }
  .marquee-container {
    width: max-content;
  }
  .marquee {
    width: max-content;
    animation: marquee 22s linear infinite;
  }
  .hide-m{
    display: none;
  }
  .hide-pc{
    display: block;
  }
}
@media only screen and (max-width: 420px) {
  .fix-left{
    gap: 2px;
  }
  .f-ab {
    padding: 5px 10px;
    font-size: 14px;
}
}


